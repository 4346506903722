<template>
  <v-sheet class="d-block d-sm-flex align-center black--text" max-width="600">
    <v-img :src="getAdNetworkImages.page_404" max-width="300" contain class="mx-auto" />
    <div>
      <h1>404</h1>
      <h2>{{ $t('page_404.page_not_found') }}</h2>
      <p>
        {{ $t('page_404.message') }}
      </p>
      <c-btn
        color="primary"
        depressed
        block
        :label="$t('page_404.dashboard')"
        @click="$router.push({ name: getMainRouteName })"
      />
    </div>
  </v-sheet>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NotFound',
    components: {
      CBtn
    },
    computed: {
      ...mapGetters('settings', ['getMainRouteName', 'getAdNetworkImages'])
    }
  }
</script>
